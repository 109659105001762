var axios = require('axios')

var app = new Vue({
  el: '#app',
  data: {
    isError: false,
    errorMessage: '',
    showError: false,
    token: '',
    loginOnly: true,
    passwordReset: false,
    updatePassword: false,
    input: {
      email: '',
      password: '',
      passwordConfirmation: ''
    }
  },
  mounted() {
    let params = (new URL(document.location)).searchParams
    this.token = params.get("token")

    if (this.token) {
      this.loginOnly = false
      this.passwordReset = false
      this.updatePassword = true
    } else {
      this.loginOnly = true
    }
  },
  methods: {
    login() {
      axios.post('/api/login', {
          email: this.input.email,
          password: this.input.password
        })
        .then((response) => {
          if (response.data.ok) {
            window.location.href = '/'
          } else {
            this.flashMessage(response)
          }
        })
    },
    toggleReset() {
      this.loginOnly = false
      this.passwordReset = true
      this.errorMessage = 'A password reset link will be sent to this email.'
      this.isError = false
    },
    flashMessage(response) {
      this.isError = !response.data.ok
      this.errorMessage = response.data.message
      this.showError = true
    },
    requestPassword() {
      this.passwordReset = false
      this.updatePassword = false
      this.input.password = ''

      axios.post('/api/users/reset-password', {
        email: this.input.email
      })
      .then((response) => {
        this.flashMessage(response)
        window.location.href = '/'
      })
    },
    resetPassword() {
      if (this.input.password !== this.input.passwordConfirmation) {
        this.isError = true
        this.errorMessage = 'Password does not match.'
        this.showError = true
      } else {
        axios.post('/api/users/reset-token', {
          token: this.token,
          password: this.input.password
        })
        .then((response) => {
          if (response.data.ok){
            window.location.href = '/'
          } else {
            this.flashMessage(response)
            this.updatePassword = false
            this.passwordReset = true
          }
        })
      }
    }
  }
})
